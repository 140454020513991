export const Keys = {
  getVehicle: sku => ["getVehicle", { id: sku }],
  getFreightSimulation: (sku, zipcode) => [
    "getFreightSimulation",
    { id: sku, zipcode: zipcode }
  ],
  getFeaturedGroups: () => ["getFeaturedGroups"],
  getOrdersByAd: sku => ["getOrdersByAd", { id: sku }],
  getBestOffer: sku => ["getBestOffer", { id: sku }],
  getUserReferral: id => ["getUserReferral", { id }],
  getOrder: uuid => ["getOrder", { id: uuid }],

  postOffer: () => ["postOffer"],
  postSearchSimilarVehicles: (sku, brand, group) => [
    "postSearchSimilarVehicles",
    { id: sku, brand, group }
  ],
  postFreightSimulation: () => ["postFreightSimulation"],
  passwordRecovery: () => ["passwordRecovery"],
  postResendConfirmation: () => ["postResendConfirmation"],
  postConsent: () => ["postConsent"]
};
